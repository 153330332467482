import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import {
    Row,
    Col,
    FormGroup,
    Button,
    Input,
} from 'reactstrap'

export default class FooterForm extends Component {
    constructor() {
        super();
        this.state = {
            isValid: false
        }
    }
    handleUsernameChange = (e) => {
        this.setState({isValid: e.target.checkValidity()});
    }
    render() {
        return (
            <form>
                <Row className="justify-content-center">
                    <Col lg="5" sm="6" className="px-1">
                        <Row className="justify-content-center" style={{ background: '#fff', padding: '4px', borderRadius: '50px', overflow: 'hidden' }}>
                            <FormGroup style={{ flex: 1 }} className={`m-0 ${this.state.isValid ? "has-success" : "has-danger"}`}>
                                <Input
                                    className={`form-control-alternative ${this.state.isValid ? "is-valid" : "is-invalid"}`}
                                    placeholder="E-Mail"
                                    style={{ height: '40px', boxShadow: 'none' }}
                                    onChange={this.handleUsernameChange}
                                    type="email"
                                    required
                                />
                            </FormGroup>
                            <Button 
                                style={{ borderRadius: '50px', height: '40px', lineHeight: '1.4' }} 
                                color="tigreal" 
                                className="m-0" 
                                to="/contact-us" 
                                tag={NavLink}
                            >
                                Submit
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </form>
        )
    }
}
