import React, { Component } from 'react'
import { Switch, Route, Redirect } from "react-router-dom";
import { Transition, TransitionGroup } from 'react-transition-group';

import { play, exit } from '../transition/timeline'

import Index from "../../views/Index";
import ProductWeb from "../../views/ProductWeb";
import ProductMobile from "../../views/ProductMobile";
import Contact from "../../views/Contact";

// import UnderConstruxtion from "../Misc/UnderConstruxtion";

export default class Main extends Component {
    render() {
        return (
            <Route render={({ location }) => {
                return (
                    <TransitionGroup component={null}>
                        <Transition
                        key={location.pathname}
                        appear={true}
                        onEnter={(node, appears) => play(node, appears)}
                        onExit={(node, appears) => exit(node, appears)}
                        timeout={{enter: 750, exit: 150}}
                        >
                            <Switch>
                                <Route path="/" exact render={props => <Index {...props} />} />
                                <Route path="/web-app" exact render={props => <ProductWeb {...props} />} />
                                <Route path="/mobile-app" exact render={props => <ProductMobile {...props} />} />
                                <Route path="/contact-us" exact render={props => <Contact {...props} />} />
                                <Redirect to="/" />
                            </Switch>
                        </Transition>
                    </TransitionGroup>
                )
            }}/>
        )
    }
}
