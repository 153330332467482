import { TimelineLite } from 'gsap';

export const play = (node, appears) => {
    const tl = new TimelineLite({ paused: true });

    node.classList.add("ftz-play");
    tl.fromTo(node, 1.5, 
        {
            // left: '-100%', 
            // top: '50%', 
            // minHeight: '2vh', 
            // position: 'absolute',
            opacity: 0,
            // transform: 'scale(0)',
        }, {
            // left: '0%', 
            // top: '0%', 
            // minHeight: '100vh', 
            // position: 'relative',
            opacity: 1,
            // transform: 'scale(1)',
            onComplete: function() {
                node.classList.remove("ftz-play");
            }
        }
    )
    tl.play()
}

export const exit = (node, nodeBefore) => {
    // const tl = new TimelineLite({ paused: true });
    
    // tl.to(node, 0.15, { autoAlpha: 0, ease: Power1.easeOut });
    // tl.play();
}
