import React, { Component } from 'react';
import './App.scss';

import NavBar from './components/Navbar/Navbar';
import Main from './components/Main/Main';
// import Footer from './components/Footer/Footer';
import ScrollTop from './components/Main/ScrollTop';

class App extends Component {
    authenticate(){
        return new Promise(resolve => setTimeout(resolve, 2000))
    }

    componentDidMount(){
        this.authenticate().then(() => {
            const ele = document.getElementById('ipl-progress-indicator')
            if(ele){
                ele.classList.add('available');
                setTimeout(() => {
                    ele.outerHTML = '';
                }, 2000)
            }
        })
    }

    render() {
        return (
            <ScrollTop>
                <div className="App">
                    <NavBar />
                    <Main />
                </div>
            </ScrollTop>
        );
    }
}

export default App;