import React from "react";

// reactstrap components
import { Container, Row, Col, Carousel, CarouselControl, CarouselItem } from "reactstrap";

import bg from '../../assets/images/theme/background-genesys.jpg'

class Hero extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            activeIndex: 0,
            items: [{
                src: require('../../assets/images/theme/product/accounting.png'),
                altText: 'Accounting System',
                caption: 'Software accounting yang dapat membantu mengelola keuangan perusahaan Anda.'
            }, {
                src: require('../../assets/images/theme/product/portal.png'),
                altText: 'Admin Portal',
                caption: 'Memberikan pengaturan fitur pada aplikasi yang terintegrasi dengan mudah.'
            }, {
                src: require('../../assets/images/theme/product/smartdealer.png'),
                altText: 'SMARTDEALER',
                caption: 'Merupakan aplikasi pembelian unit kendaraan motor secara online.'
            }, {
                src: require('../../assets/images/theme/product/maindealer.png'),
                altText: 'Main Dealer',
                caption: 'Sebuah produk system yang dapat mengatur sistem penjualan dari sub-dealer.'
            }]
        };
    }

    onExiting = () => {
        this.animating = true;
    }

    onExited = () => {
        this.animating = false;
    }

    next = () => {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.state.items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous = () => {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.state.items.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    render() {
        const { activeIndex } = this.state;
        const slides = this.state.items.map((item) => {
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item.src}
                >
                        <Row>
                            <Col lg="7" className="d-flex flex-column justify-content-center">
                                <h1 className="display-3 text-white">
                                    {item.altText}
                                </h1>
                                <p className="lead text-white mt-0">
                                    {item.caption}
                                </p>
                            </Col>
                            <Col lg="5" className="d-flex flex-column justify-content-center">
                                <img style={{width: '100%'}} src={item.src} alt={item.altText} />
                                {/* <img alt="..." src={require('../../assets/images/theme/product/accounting.png')} /> */}
                            </Col>
                        </Row>
                    
                </CarouselItem>
            );
        });
        return (
            <>
                <section className="section p-10 home-hero section-shaped" style={{ height: '100vh' }}>
                    <div className="shape shape-style-1 shape-default" style={{ background: `url(${bg})`, backgroundPosition: 'center bottom', backgroundSize: 'cover' }}>
                        <span />
                        <span />
                        <span />
                        <span />
                        <span />
                        <span />
                        <span />
                        <span />
                        <span />
                    </div>
                    <Container className="py-lg-md d-flex align-items-center">
                        <Carousel
                            className="home-carousel"
                            activeIndex={activeIndex}
                            next={this.next}
                            previous={this.previous}
                        >
                            {slides}
                            <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                            <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                        </Carousel>
                    </Container>

                    {/* <Container className="py-lg-md d-flex align-items-center">
                        <Row>
                            <Col lg="7" className="d-flex flex-column justify-content-center">
                                <h1 className="display-3 text-white">
                                    Accounting System
                                </h1>
                                <p className="lead text-white mt-0">
                                    Software accounting yang dapat membantu mengelola keuangan persuahaan Anda.
                                </p>
                            </Col>
                            <Col lg="5">
                                <img alt="..." src={require('../../assets/images/theme/product/accounting.png')} />
                            </Col>
                        </Row>
                    </Container> */}
                </section>
            </>
        );
    }
}

export default Hero;