import React, { Component } from 'react';
import CONFIG from '../../store/Config';
import {
    Container,
    Row,
    Col,
} from 'reactstrap'
import bg from '../../assets/images/theme/background-footer.jpg';
import FooterForm from './FooterForm';

export default class Footer extends Component {
    render() {
        return (
            <>
                <footer className="bg-secondary">
                    <section className="section py-5 section-components text-center" style={{ backgroundImage: `url(${bg})`, backgroundPosition: 'center bottom', backgroundSize: 'cover' }}>
                        <div className="py-5">
                            <Container>
                                {/* Inputs (alternative) */}
                                <div className="mb-3 text-white">
                                    <div className="">For Product Presentation,</div>
                                    <div className="pb-2">please contact us at <span className="text-dark font-weight-bold">{CONFIG.COMPANY.EMAIL}</span></div>
                                    <div className="">or you can drop your email</div>
                                </div>
                                <FooterForm />
                            </Container>
                        </div>
                    </section>
                    <Container className="p-3 text-center">
                        <Row className="align-items-center justify-content-md-between">
                            <Col md="12">
                                <div className="copyright">
                                    © {new Date().getFullYear()}{" "}<a href="./" target="_blank">{CONFIG.COMPANY.NAME}</a>.
                                </div>
                            </Col>
                            {/* <Col md="6">
                                <Nav className="nav-footer justify-content-end">
                                    <NavItem>
                                        <NavLink to="/contact-us" tag={Link}>About Us</NavLink>
                                    </NavItem>
                                </Nav>
                            </Col> */}
                        </Row>
                    </Container>
                </footer>
            </>
        )
    }
}
