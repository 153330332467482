export default {
    COMPANY: {
        TITLE: 'PT',
        NAME: 'Genesys Application Indonesia',
        EMAIL: 'admin@genesys.co.id',
        EMAIL2: '-',
        MOBILE: '(62)21 - 29426708',
        ADDRESS: 'Plaza Sentral lt.19 Jl. Jend. Sudirman Kav. 47',
        CITY: 'Jakarta',
        POST: '12930',
        COUNTRY: 'Indonesia'
    }
}