import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
    Container,
    Row,
    Col,
    Button
} from 'reactstrap'

export default class HomeProducts extends Component {
    render() {
        return (
            <>
                <section className="section section-lg bg-whitey bg-custom bg-custom-web">
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col className="order-md-2" md="6">
                                <div className="">
                                    
                                    <h3>
                                        <div className="icon icon-md icon-shape bg-gussion shadow rounded-circle mr-2 text-whitey">
                                            <i className="ni ni-laptop" />
                                        </div>
                                        WEB BASED APPLICATION
                                    </h3>
                                    <p>
                                        Genesys merupkan sebuah software yang memudahkan pengguna, 
                                        software kami memiliki fungsi yang memfasilitasi proses 
                                        pembelian serta penjualan untuk bisnis Anda.
                                    </p>
                                    <div className="btn-wrapper">
                                        <Button
                                            className="mt-3 mt-md-0"
                                            color="default"
                                            to="/web-app"
                                            tag={Link}
                                        >
                                            View our works
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                            <Col className="order-md-1" md="6">
                                <img
                                    alt="..."
                                    style={{ width: '100%' }}
                                    className="img-fluid floating"
                                    src={require("../../assets/images/theme/asset1.png")}
                                />
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="section section-lg bg-secondary bg-custom bg-custom-mobile">
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col className="order-md-2" md="6">
                                <img
                                    alt="..."
                                    style={{ width: '100%' }}
                                    className="img-fluid floating"
                                    src={require("../../assets/images/theme/asset2.png")}
                                />
                            </Col>
                            <Col className="order-md-1" md="6">
                                <div className="">
                                    <h3>
                                        <div className="icon icon-md icon-shape bg-gussion shadow rounded-circle mr-2 text-whitey">
                                            <i className="ni ni-mobile-button" />
                                        </div>
                                        MOBILE APPS
                                    </h3>
                                    <p>
                                        Genesys Application Indonesia dapat membantu Anda dalam 
                                        membangun sebuah aplikasi yang bermanfaat untuk bisnis 
                                        dan keperluan perusahaan Anda.
                                    </p>
                                    <div className="btn-wrapper">
                                        <Button
                                            className="mt-3 mt-md-0"
                                            color="default"
                                            to="/mobile-app"
                                            tag={Link}
                                        >
                                            View our works
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

