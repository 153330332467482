import React, { Component } from 'react';
import Footer from '../components/Footer/Footer'
import classnames from "classnames";

import { 
    Container, 
    Row, 
    Col, 
    CardImg,
    Nav,
    NavItem,
    NavLink,
    Card,
    CardBody,
    TabPane,
    TabContent 
} from "reactstrap";

import bg from '../assets/images/theme/background-our-works-web.jpg'

export default class ProductWeb extends Component {
    constructor(props) {
        super(props)
        this.state = {
            iconTabs: 0,
            webTab: 0,
            items: [{
                id: 'webTab0',
                title: 'Main Dealer',
                description: 'Genesys Main Dealer adalah sebuah produk system yang dapat mengatur sistem penjualan dari sub-dealer. System Main dealer ini memberikan kemudahan untuk Anda dalam mengelola trasnsaksi unit pada cabang dealer Anda',
                image: require("../assets/images/theme/product/maindealer.png")
            }, {
                id: 'webTab1',
                title: 'Sub Dealer',
                description: 'Genesys Sub Dealer adalah sebuah produk system yang dapat membantu Anda dalam mengelola bisnis dealer. System dealer ini memberikan kemudahan untuk Anda dalam mengelola trasnsaksi unit secara mudah dan cepat',
                image: require("../assets/images/theme/product/subdealer.png")
            }, {
                id: 'webTab2',
                title: 'Accounting',
                description: 'Genesys Acounting System merupakan software yang dapat membantu Anda dalam mengelola sistem keuangan perusahaan. Penggunaan web based sistem ini sangat mudah sehingga memudahkan Anda dalam mengatur alur keuangan bisnis Anda',
                image: require("../assets/images/theme/product/accounting.png")
            }, {
                id: 'webTab3',
                title: 'Admin Portal',
                description: 'Admin Portal System merupakan sebuah web based system untuk pengaturan aplikasi. Segala pengaturan fitur pada aplikasi yang terintegrasi dengan mudah dapat diatur melalui Admin portal System',
                image: require("../assets/images/theme/product/portal.png")
            }]
        };
    }

    toggleNavs = (e, state, index) => {
        e.preventDefault();
        this.setState({
            [state]: index
        });
    };
    
    render() {
        return (
            <>
                <main ref="main">
                    <section className="section section-half section-shaped">
                        <div className="shape shape-style-1 shape-default" style={{ background: `url(${bg})`, backgroundPosition: 'center bottom', backgroundSize: 'cover' }}>
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                        </div>
                        {/* <Container className="py-lg-md d-flex align-items-center">
                                <Row>
                                    <Col lg="12">
                                        <h1 className="display-3 text-white">
                                            GENESYS Web Based Application{" "}
                                        </h1>
                                        <p className="lead text-white">
                                            "Pengembangan perangkat lunak terbaik<br /> untuk mendukung sistem informasi"
                                        </p>
                                    </Col>
                                </Row>
                        </Container> */}
                    </section>

                    <section className="section bg-gradient-whitey bg-custom bg-custom-web">
                        <Container>
                            <Row className="row-grid justify-content-center">
                                <Col className="text-center" lg="8">
                                    <div className="text-center">
                                        <h4 className="display-4 mb-0">WEB BASED APPLICATION</h4>
                                        <h6 className="display-6 mb-5 mt-0 text-dark">
                                            Genesys merupkan sebuah software yang memudahkan pengguna, software kami  menyediakan data – data terbaru setiap saat. Memiliki fungsi yang memfasilitasi proses pembelian serta penjualan berbagai macam format laporan. Dibuat secara grafis, akurat dan serbaguna.
                                        </h6>
                                        <CardImg
                                            alt="..."
                                            src={require("../assets/images/theme/asset1.png")}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    <section className="section py-5 bg-our-works">
                        <Container>
                            <Row className="justify-content-center">
                                <Col className="mt-5 mt-lg-0" lg="12">
                                    {/* Menu */}
                                    <div className="nav-wrapper">
                                        <Nav
                                            className="nav-fill flex-column flex-md-row mb-5"
                                            id="tabs-icons-text"
                                            pills
                                            role="tablist"
                                        >
                                            {
                                                this.state.items.map((item, key) => {
                                                    return (
                                                        <NavItem key={key}>
                                                            <NavLink
                                                                aria-selected={this.state.webTab === key}
                                                                className={classnames("ftz-pills mb-sm-3 mb-md-0", {
                                                                    active: this.state.webTab === key
                                                                })}
                                                                onClick={e => this.toggleNavs(e, "webTab", key)}
                                                                href="#fitz"
                                                                role="tab"
                                                            >
                                                                {item.title}
                                                            </NavLink>
                                                        </NavItem>
                                                    )
                                                })
                                            }
                                        </Nav>
                                    </div>
                                    <Card className="bg-transparent text-whitey">
                                        <CardBody>
                                            <TabContent activeTab={"webTab" + this.state.webTab}>
                                                {
                                                    this.state.items.map((item, key) => {
                                                        return (
                                                            <TabPane key={key} tabId={`webTab${key}`}>
                                                                <Row className="row-grid">
                                                                    <Col className="order-md-1" md="7">
                                                                        <img
                                                                            alt="..."
                                                                            style={{ width: '100%' }}
                                                                            className="img-fluid"
                                                                            src={item.image}
                                                                        />
                                                                    </Col>
                                                                    <Col className="order-md-2" md="5">
                                                                        <div className="">
                                                                            <h3 className="text-whitey">{item.title}</h3>
                                                                            <p className="description" dangerouslySetInnerHTML={{ __html: item.description }}></p>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </TabPane>
                                                        )
                                                    })
                                                }
                                            </TabContent>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </main>
                <Footer />
            </>
        )
    }
}
