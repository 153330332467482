import React, { Component } from 'react';
import Footer from '../components/Footer/Footer'
import classnames from "classnames";

import { 
    Container, 
    Row, 
    Col, 
    CardImg,
    Nav,
    NavItem,
    NavLink,
    Card,
    CardBody,
    TabPane,
    TabContent 
} from "reactstrap";

import bg from '../assets/images/theme/background-our-works-mobile.jpg'

export default class ProductMobile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            iconTabs: 0,
            mobileTab: 0,

            items: [{
                id: 'mobileTab0',
                title: 'Smart Dealer',
                description: 'Genesys Application Indonesia dapat membantu Anda dalam membangun sebuah aplikasi yang bermanfaat untuk bisnis dan keperluan perusahaan Anda.',
                image: require("../assets/images/theme/product/smartdealer-mobile.png")
            }, {
                id: 'mobileTab1',
                title: 'Admin Systems',
                description: 'Genesys Application Indonesia dapat membantu Anda dalam membangun sebuah aplikasi yang bermanfaat untuk bisnis dan keperluan perusahaan Anda. ',
                image: require("../assets/images/theme/product/adminsystems-mobile.png")
            }]
        };
    }

    toggleNavs = (e, state, index) => {
        e.preventDefault();
        this.setState({
            [state]: index
        });
    };

    render() {
        return (
            <>
                <main ref="main">
                    <section className="section section-half section-shaped">
                        <div className="shape shape-style-1 shape-default" style={{ background: `url(${bg})`, backgroundPosition: 'center bottom', backgroundSize: 'cover' }}>
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                        </div>
                        {/* <Container className="py-lg-md d-flex align-items-center">
                                <Row>
                                    <Col lg="12">
                                        <h1 className="display-3 text-white">
                                            GENESYS Mobile Application{" "}
                                        </h1>
                                        <p className="lead text-white">
                                            "Pengembangan perangkat lunak terbaik<br /> untuk mendukung sistem informasi"
                                        </p>
                                    </Col>
                                </Row>
                        </Container> */}
                    </section>

                    <section className="section bg-gradient-whitey bg-custom bg-custom-mobile">
                        <Container>
                            <Row className="row-grid justify-content-center">
                                <Col className="text-center" lg="8">
                                    <div className="text-center">
                                        <h4 className="display-4 mb-0">Mobile Apps</h4>
                                        <h6 className="display-6 mb-5 mt-0 text-dark">
                                            Genesys merupkan sebuah software yang memudahkan pengguna, software kami  menyediakan data – data terbaru setiap saat. Memiliki fungsi yang memfasilitasi proses pembelian serta penjualan berbagai macam format laporan. Dibuat secara grafis, akurat dan serbaguna.
                                        </h6>
                                        <CardImg
                                            alt="..."
                                            src={require("../assets/images/theme/asset2.png")}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    <section className="section py-5 bg-our-works">
                        <Container>
                            <Row className="justify-content-center">
                                <Col className="mt-5 mt-lg-0" lg="12">
                                    {/* Menu */}
                                    <div className="nav-wrapper">
                                        <Nav
                                            className="nav-fill flex-column flex-md-row mb-5"
                                            id="tabs-icons-text"
                                            pills
                                            role="tablist"
                                        >
                                            {
                                                this.state.items.map((item, key) => {
                                                    return (
                                                        <NavItem key={key}>
                                                            <NavLink
                                                                aria-selected={this.state.mobileTab === key}
                                                                className={classnames("ftz-pills mb-sm-3 mb-md-0", {
                                                                    active: this.state.mobileTab === key
                                                                })}
                                                                onClick={e => this.toggleNavs(e, "mobileTab", key)}
                                                                href="#fitz"
                                                                role="tab"
                                                            >
                                                                {item.title}
                                                            </NavLink>
                                                        </NavItem>
                                                    )
                                                })
                                            }
                                        </Nav>
                                    </div>
                                    <Card className="bg-transparent text-whitey">
                                        <CardBody>
                                            <TabContent activeTab={"mobileTab" + this.state.mobileTab}>
                                                {
                                                    this.state.items.map((item, key) => {
                                                        return (
                                                            <TabPane key={key} tabId={`mobileTab${key}`}>
                                                                <Row className="row-grid">
                                                                    <Col className="order-md-1" md="7">
                                                                        <img
                                                                            alt="..."
                                                                            style={{ width: '100%' }}
                                                                            className="img-fluid"
                                                                            src={item.image}
                                                                        />
                                                                    </Col>
                                                                    <Col className="order-md-2" md="5">
                                                                        <div className="">
                                                                            <h3 className="text-whitey">{item.title}</h3>
                                                                            <p className="description" dangerouslySetInnerHTML={{ __html: item.description }}></p>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </TabPane>
                                                        )
                                                    })
                                                }
                                            </TabContent>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </main>
                <Footer />
            </>
        )
    }
}