import React from "react";
import Footer from '../components/Footer/Footer'

// index page sections
import Hero from "./index/Hero";
import Introduction from "./index/Introduction";
import Frameworks from "./index/Frameworks";
import HomeProducts from "./index/HomeProducts";
import ApiSupport from "./index/ApiSupport";

class Index extends React.Component {
    render() {
        return (
            <>
                <main ref="main">
                    <Hero />
                    <Introduction />
                    <HomeProducts />
                    <ApiSupport />
                    <Frameworks />
                </main>
                <Footer />
            </>
        );
    }
}

export default Index;
