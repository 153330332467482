import React from "react";
import { Link, NavLink } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
    Collapse,
    NavItem,
    // NavLink,
    DropdownMenu,
    DropdownToggle,
    // DropdownItem,
    UncontrolledDropdown,
    Media,
    NavbarBrand,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
} from "reactstrap";

class NavBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    closeNavbar = () => {
        this.setState({
            isOpen: false
        });
    }

    componentDidMount() {
        let headroom = new Headroom(document.getElementById("navbar-main"));
        // initialise
        headroom.init();
    }
    render() {
        return (
            <>
            <header className="header-global">
                <Navbar
                    className="navbar-main navbar-transparent navbar-light headroom"
                    expand="lg"
                    id="navbar-main"
                >
                    <Container>
                        <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                            <img
                                alt="..."
                                src={require("../../assets/images/brand/genesys-white.png")}
                            />
                        </NavbarBrand>
                        <button className="navbar-toggler" id="navbar_global" onClick={this.toggle}>
                            <span className="navbar-toggler-icon" />
                        </button>
                        <Collapse isOpen={this.state.isOpen} navbar toggler="#navbar_global">
                            <div className="navbar-collapse-header">
                                <Row>
                                    <Col className="collapse-brand text-center" xs="12">
                                        <Link to="/" onClick={this.closeNavbar}>
                                            <img alt="..." src={require("../../assets/images/brand/genesys-white.png")}
                                        />
                                        </Link>
                                    </Col>
                                    <div className="collapse-close" xs="12">
                                        <button className="navbar-toggler" id="navbar_global" onClick={this.toggle}>
                                            <span />
                                            <span />
                                        </button>
                                    </div>
                                </Row>
                            </div>
                            <Nav className="navbar-nav-hover align-items-lg-center ml-lg-auto" navbar>
                                <NavItem>
                                    <NavLink className="nav-link text-light" to="/"  exact={true} onClick={this.closeNavbar}>Home</NavLink>
                                </NavItem>

                                <UncontrolledDropdown nav>
                                    <DropdownToggle nav>
                                        <i className="ni ni-ui-04 d-lg-none mr-1" />
                                        <span className="nav-link-inner--text text-light">Our Works</span>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-md" right>
                                        {/* <DropdownItem>
                                            <NavLink className="nav-link text-light" to="/web-app" onClick={this.closeNavbar}>WEB BASED APPLICATION</NavLink>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <NavLink className="nav-link text-light" to="/mobile-app" onClick={this.closeNavbar}>MOBILE APPS</NavLink>
                                        </DropdownItem> */}
                                        <div className="dropdown-menu-inner">
                                            <Media
                                                className="d-flex align-items-center"
                                                to="/web-app"
                                                tag={NavLink}
                                                onClick={this.closeNavbar}
                                            >
                                                <div className="icon icon-sm icon-shape bg-gradient-light rounded-circle text-dark">
                                                    <i className="ni ni-spaceship" />
                                                </div>
                                                <Media body className="ml-3">
                                                    <h6 className="heading text-light mb-md-1">
                                                        WEB BASED APPLICATION
                                                    </h6>
                                                    {/* <p className="description d-none d-md-inline-block mb-0">
                                                        "Pengembangan perangkat lunak terbaik
                                                        untuk mendukung sistem informasi"
                                                    </p> */}
                                                </Media>
                                            </Media>
                                            <Media
                                                className="d-flex align-items-center"
                                                to="/mobile-app"
                                                tag={NavLink}
                                                onClick={this.closeNavbar}
                                            >
                                                <div className="icon icon-sm icon-shape bg-gradient-light rounded-circle text-dark">
                                                    <i className="ni ni-palette" />
                                                </div>
                                                <Media body className="ml-3">
                                                    <h6 className="heading text-light mb-md-1">
                                                        MOBILE APPS
                                                    </h6>
                                                    {/* <p className="description d-none d-md-inline-block mb-0">
                                                        "Pengembangan perangkat lunak terbaik
                                                        untuk mendukung sistem informasi"
                                                    </p> */}
                                                </Media>
                                            </Media>
                                        </div>
                                    </DropdownMenu>
                                </UncontrolledDropdown>

                                <NavItem>
                                    <NavLink to="/contact-us" className="nav-link text-light"  onClick={this.closeNavbar}>Contact</NavLink>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
            </>
        );
    }
}

export default NavBar;
