import React, { Component } from 'react';
import { 
    Container,
} from "reactstrap";
import CONFIG from '../store/Config';

import bg from '../assets/images/theme/background-genesys.jpg';
import Logo from '../assets/images/brand/genesys-down.png';
import FooterForm from '../components/Footer/FooterForm';


export default class Contact extends Component {
    render() {
        return (
            <>
                <main ref="main">
                    <section className="section section-full section-shaped">
                        <div className="shape shape-style-1 shape-default" style={{ background: `url(${bg})`, backgroundPosition: 'center bottom', backgroundSize: 'cover' }}>
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                        </div>
                        <Container className="py-lg-md d-flex align-items-center justify-content-center flex-column">
                            <img alt="Genesys" src={Logo} style={{ maxWidth: '300px' }} />
                            <h6 className="display-6 text-whitey text-center">
                                {CONFIG.COMPANY.ADDRESS} <br />{CONFIG.COMPANY.CITY} {CONFIG.COMPANY.POST} - {CONFIG.COMPANY.COUNTRY}. Telp: {CONFIG.COMPANY.MOBILE}
                            </h6>
                            <section style={{ width: '100%' }} className="section py-5 section-components text-center">
                                <Container>
                                    {/* Inputs (alternative) */}
                                    <div className="mb-3 text-white">
                                        <div className="">For Product Presentation,</div>
                                        <div className="pb-2">please contact us at <span className="text-info font-weight-bold">{CONFIG.COMPANY.EMAIL}</span></div>
                                        <div className="">or you can drop your email</div>
                                    </div>
                                    <FooterForm />
                                </Container>
                            </section>
                        </Container>
                    </section>
                </main>
            </>
        )
    }
}
