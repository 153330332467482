import React, { Component } from 'react'
import {
    Container,
    Row,
    Col
} from 'reactstrap'

export default class Introduction extends Component {
    constructor(props) {
        super(props)
        this.state = {
            intro: 'We are team of computer experts that strive to produce the most amazing and cutting edge designs and code possible. We love to push the boundaries of what is possible with UI/UX, PHP, CSS, and JavaScript, developing new technologies and techniques as we go.',
            intro_items: [{
                icon: 'ni ni-settings',
                title: 'Enterprise Solution',
                desc: 'Welcome to the world of Agape Software. Our company is building software information systems for any companies.',
                desc2: 'Providing solution to business management and information accessability for any clients.'
            }, {
                icon: 'ni ni-ruler-pencil',
                title: 'Web Development',
                desc: 'We were established originally in early 2005 as a software company and our operations have expanded over the years.',
                desc2: 'Let us model your vision and ideas into highly impressive and effective web solutions.'
            }, {
                icon: 'ni ni-atom',
                title: 'IT Consultant',
                desc: 'We are able to develop all your software requirements from front end to back office systems.',
                desc2: 'Never missed opportunity to move your business forward. Leave the IT to the professionals.'
            }]
        }
    }
    render() {
        return (
            <section className="section section-md pt-0 bg-gussion">
                <Container>
                    <Row className="text-center justify-content-center">
                        <Col lg="12">
                            <img alt="..." style={{ maxWidth: '410px', width: '100%' }} src={require('../../assets/images/theme/genesys-home-top.png')} />
                            <p className="lead text-white description">
                                {this.state.intro}
                            </p>
                        </Col>
                    </Row>
                    <Row className="row-grid mt-5 text-center">
                        {this.state.intro_items.map((val, key) => {
                            return (
                                <Col key={key} lg="4" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                                    <div>
                                        <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-gussion">
                                            <i className={`${val.icon} text-gussion`} />
                                        </div>
                                        <h5 className="text-white mt-3">{val.title}</h5>
                                        <p className="text-white mt-3 description">{val.desc}</p>
                                    </div>
                                    <p className="text-info mt-0 description">{val.desc2}</p>
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
            </section>
        )
    }
}
