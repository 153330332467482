import React, { Component } from 'react'
import {
    Container,
    Row,
    Col,
} from 'reactstrap'

export default class ApiSupport extends Component {
    render() {
        return (
            <>
                <section className="section section-lg bg-secondary bg-custom bg-custom-api-support text-center">
                    <Container>
                        <h4 className="display-4 text-whitey">API Support System</h4>
                        <Row className="row-grid align-items-center justify-content-center">
                            <Col className="order-md-2" md="6">
                                <img
                                    alt="..."
                                    style={{ width: '100%' }}
                                    className="img-fluid"
                                    src={require("../../assets/images/theme/images-api-support.svg")}
                                />
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}

